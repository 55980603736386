.categories {
    margin-top: 40px;
    margin-left: 17%;
    margin-right: 17%;
    .header-categories {
        font-size: 32px;
        font-weight: bold;
    }
    .list-categories {
        
        .item-list {
            margin-top: 30px;
        }
    }
}