.list-apps-detail {
    margin-top: 50px;
    .info-new-apps {
        .title-name {
            font-size: 40px;
            font-weight: bold;
        }
    }
    .body-new-apps {
        margin-top: 50px;
        .newest-apps {
            font-size: 15px;
            font-weight: bold;
            text-align: right;
            a {
                color: #0092af;
                &:hover {
                    text-decoration: underline;
                    color: #4080C6;
                }
            }
        }
        .list-item {
            margin-top: 25px;
            margin-bottom: 25px;
            display: flex;
            justify-content: flex-start;
        }
    }
}
