.detail-categories {
    text-align: left;
    .detail-categories-header {
        padding-left: 25px;
        padding-right: 25px;
        padding-top: 15px;
        padding-bottom: 15px;
        background: #e9ecef;
        font-size: 18px;
    }
    .detail-categories-body {
        padding-left: 25px;
        padding-right: 25px;
        .pagination {
            text-align: center;
            margin-top: 30px;
        }
        .title {
            margin-top: 10px;
            font-size: 30px;
            font-weight: bold;
        }
        .link {
            margin-top: 5px;
        }
        .line {
            border-top: 1px solid rgb(211, 211, 211);
            margin-top: 15px;
        }
        .detail {
            margin-top: 10px;
            .block-item-detail {
                .page {
                    background: #e9ecef;
                    padding: 5px;
                    text-align: center;
                    font-weight: bold;
                }
            }
            .title-column {
                margin-bottom: 10px;
                padding-left: 10px;
            }
            .item-detail {
                padding-left: 10px;

                padding-top: 10px;
                padding-bottom: 10px;
                border-top: 1px solid rgb(230, 230, 230);
                .icon-star {
                    color: #d8aa34;
                    .calular-incre {
                        margin-left: 5px;
                        color: white;
                        background: #27A844;
                        border-radius: 6px;
                        padding: 3px;
                        font-size: 12px;
                        
                    }
                    .calular-decre {
                        margin-left: 5px;
                        color: white;
                        background: #DC3544;
                        border-radius: 6px;
                        padding: 3px;
                        font-size: 12px;
                    }
                }
                .rank {
                    margin-top: 10px;
                    span {
                        border-radius: 10px;
                        background: #343a3f;
                        padding: 5px;
                        padding-left: 12px;
                        padding-right: 12px;
                        color: white;
                        font-weight: bold;
                        font-size: 18px;
                    }
                }
                .content-app {
                    // align-items: center;
                    display: flex;
                    .image {
                        img {
                            height: 50px;
                            width: 50px;
                            &:hover {
                                cursor: pointer;
                            }
                        }
                    }
                    .item-detail-app {
                        margin-left: 10px;
                        .name-app-shopify {
                            .link-name {
                                &:hover {
                                    text-decoration: underline;
                                }
                            }
                            .calular-incre {
                                margin-right: 5px;
                                color: white;
                                background: #27A844;
                                border-radius: 6px;
                                padding: 3px;
                                font-size: 12px;
                                
                            }
                            .calular-decre {
                                margin-right: 5px;
                                color: white;
                                background: #DC3544;
                                border-radius: 6px;
                                padding: 3px;
                                font-size: 12px;
                            }
                        }
                        .link-app-shopify {
                            margin-top: 5px;
                            &:hover {
                                color: #40a2f8;
                                text-decoration: underline;
                            }
                            .link {
                                color: black;
                                &:hover {
                                    color: #40a2f8;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
