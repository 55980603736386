.layout-landing-page {
	background-color: #ffffff;
	&-intro {
		height: max-content;
		padding: 50px 0;
		background-image:url("../../assets/images/pingme/Banner.png");
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		position: relative;
		overflow: hidden;
		height: 667px;

		.title {
			text-align: left;
			color: #fff;
			font-size: 28px;
		}
		.description {
			text-align: left;
			color: #fff;
			font-weight: 300;
			font-size: 18px;
		}

		.cta-btn {
			text-align: left;
			margin-top: 30px;
		}
	}

	&-features {
		padding: 30px 0;
		.title {
			font-size: 40px;
			font-weight: 600;
			margin-bottom: 0;
		}
		.description {
			color: #333;
			font-weight: 300;
			font-size: 18px;
		}

		.layout {
			margin-bottom: 30px;
			background: #fff;
			-webkit-box-shadow: 0px 15px 30px 0px rgb(0 0 0 / 10%);
			box-shadow: 0px 15px 30px 0px rgb(0 0 0 / 10%);
			padding: 15px 40px;
			position: relative;
		    border-radius: 20px;
		    margin-right: 10px;
			
			&-title {
				font-weight: bold;
				font-size: 18px;
				padding: 10px 0;
			}
		}
		.layout-item{
			margin-bottom: 30px;
			img{
				border-radius: 8px;
				border: 1px solid #e1e1e1;
			}
		}
	}

	&-try-free {
		padding: 50px 0;
		background-image: linear-gradient(40deg, rgba(14, 177, 75, 0.7) 0%, rgba(0, 14, 45, 0.7) 0%),
			url('../../assets/images/try-app.jpg');
		background-size: cover;
		background-position: center center;

		.title {
			color: #fff;
			font-size: 40px;
			font-weight: 600;
			margin-bottom: 0;
		}
		.description {
			color: #fff;
			font-weight: 300;
			font-size: 18px;
		}
	}
	&-pricing {
		.title {
			font-size: 40px;
			font-weight: 600;
			margin-top: 30px;
		}
		.price-table {
			width: 100%;
			border-collapse: collapse;
			border: 0 none;
			margin-top: 20px;
		}
		.price-table tr:not(:last-child) {
			border-bottom: 1px solid rgba(0, 0, 0, 0.03);
		}
		.price-table tr td {
			border-left: 1px solid rgba(0, 0, 0, 0.05);
			padding: 8px 24px;
			font-size: 14px;
		}
		.price-table tr td:first-child {
			border-left: 0 none;
			text-align: left;
		}
		.price-table tr td:not(:first-child) {
			text-align: center;
		}
		.price-table tr:nth-child(even) {
			background-color: #ffffff;
		}
		.price-table tr:hover {
			background-color: #eeeeee;
		}
		.price-table .fa-check {
			color: #000e2d;
		}
		.price-table .fa-times {
			color: #d8d6e3;
		}

		/* Highlighted column */
		.price-table tr:nth-child(2n) td:nth-child(3) {
			background-color: rgba(216, 214, 227, 0.25);
		}
		.price-table tr td:nth-child(3) {
			background-color: rgba(216, 214, 227, 0.15);
			padding: 8px 48px;
		}

		.price-table tr.price-table-head td {
			font-size: 16px;
			font-weight: 600;
			text-transform: uppercase;
		}
		.price-table tr.price-table-head {
			background-color: #000e2d;
			color: #ffffff;
		}
		.price-table td.price {
			color: #f43f54;
			padding: 16px 24px;
			font-size: 20px;
			font-weight: 600;
		}
		.price-table td.price a {
			margin-top: 16px;
		}
		.price-table td.price span {
			line-height: 40px;
		}
		.price-table td.price-table-popular {
			border-top: 3px solid #000e2d;
			color: #000e2d;
			text-transform: uppercase;
			font-size: 12px;
			padding: 12px 48px;
			font-weight: 700;
		}
		.price-table .price-blank {
			background-color: #fafafa;
			border: 0 none;
		}

		.price-table svg {
			width: 80px;
		}
	}
}
