@import "../../utils/variables.scss";
$white: #fff;
$google-blue: #4285f4;
$button-active-blue: #1669f2;
.login-web {
  display: flex;
  justify-content: center;
  padding-top: 100px;
  padding-left: 160px;
  padding-right: 245px;
  min-height: 50vh;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  .authen-box {
    .privacy-term {
      margin-top: 10px;
      display: flex;
      justify-content: flex-end;
      .privacy {
        margin-right: 20px;
      }
    }
    .title {
      margin-bottom: 60px;
      img {
        width: 320px;
      }
    }
    .form-login {
      .ant-form-item-required {
        color: #777777;
      }
      .form-input {
        height: 36px;
        border-radius: 4px;
      }
      .button-login {
        height: 36px;
        border-radius: 4px;
        background-color: $text-color;
        border-color: $text-color;
      }
      .forgot-password {
        padding-left: 80px;
        color: #676767;

        &:hover {
          cursor: pointer;
        }
      }
    }
    .login-google {
      width: 100%;
      height: 42px;
      background-color: $google-blue;
      border-radius: 2px;
      box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
      .google-icon-wrapper {
        position: absolute;
        margin-top: 1px;
        margin-left: 1px;
        width: 40px;
        height: 40px;
        border-radius: 2px;
        background-color: $white;
      }
      .google-icon {
        position: absolute;
        margin-top: 11px;
        margin-left: 11px;
        width: 18px;
        height: 18px;
        left: 0;
      }
      .btn-text {
        line-height: 42px;
        margin: 11px 11px 0 0;
        color: $white;
        font-size: 14px;
        letter-spacing: 0.2px;
      }
      &:hover {
        cursor: pointer;
        box-shadow: 0 0 6px $google-blue;
      }
      &:active {
        background: $button-active-blue;
      }
    }
  }
}
