.developer-detail {
    margin-left: 17%;
    margin-right: 17%;
    .list-developer-detail {
        margin-top: 30px;
        .info-developer-detail {
            .title-name {
                font-size: 40px;
                font-weight: bold;
            }
        }
        .body-developer-detail {
            margin-top: 30px;
        }
        .pagination {
            margin-top: 30px;
        }
    }
}
