.popup-add-competitor {
    .input-competitor {
        .result-search {
            margin-top: 20px;
            max-height: 300px;
            height: 300px;
            overflow-y: auto;
            .item-name {
                padding: 7px;
                cursor: pointer;
                border-bottom: 1px solid rgb(226, 226, 226);
                &:hover {
                    background-color: rgb(241, 241, 241);
                }
            }
            .item-active {
                background-color: rgb(211, 180, 180);
                &:hover {
                    background-color: rgb(211, 180, 180);
                }
            }
        }
        .no-data {
            text-align: center;
            margin-top: 10px;
        }

        .save-competitor {
            text-align: center;
            margin-top: 20px;
        }
    }
}