.footer-sasi {
	display: flex;
	width: 100%;
	.footer-left {
		width: 70%;
		text-align: left;
		.info-sasi {
			color: white;
			font-size: 16px;
			.info-work {
				color: white;
				&:hover {
					text-decoration: underline;
				}
			}
		}
		.copy-right {
			color: rgb(143, 143, 143);
			margin-top: 20px;
			.link-policy {
				padding-left: 15px;
				color: rgb(143, 143, 143);
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
	.footer-right {
		width: 30%;
		text-align: right;
		.logo-wrapper {
			display: flex;
		}
    .title-wrapper {
      display: flex;
      flex-direction: column;
      span {
        text-align: start;
				color: white;
				margin-left: 7px;
			}
    }
	}
}

@media (max-width: 1200px) {
	.footer-sasi {
		display: block;

		.footer-left {
			width: 100%;
		}
		.footer-right {
			margin-top: 20px;
		}
	}
}
