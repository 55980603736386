.detail-app {
    .detail-app-header {
        text-align: left;
        padding: 15px 50px;
        background-color: #e9ecef;
        .link {
            font-size: 16px;
            a {
                color: #4cadfe;
                &:hover {
                    color: #37a5ff;
                    text-decoration: underline;
                }
            }
        }
    }
    .date-range {
        .title-name {
            font-weight: bold;
        }
        padding: 30px 50px 10px 50px;
        text-align: left;
        .icon-search-date {
            font-size: 20px;
            cursor: pointer;
            padding-left: 20px;
        }
    }

    .body-detail-app {
        padding: 0 50px;
        .table-categories-position {
            margin-top: 20px;
            text-align: left;
            border: 1px solid rgb(219, 219, 219);
            border-radius: 4px;
            .title-cate {
                font-size: 18px;
                padding-bottom: 10px;
                padding: 15px;
            }
            .item-cate {
                padding: 15px;
                border-top: 1px solid rgb(219, 219, 219);

                .calular-incre {
                    margin-left: 5px;
                    color: white;
                    background: #27a844;
                    border-radius: 6px;
                    padding: 3px;
                    font-size: 12px;
                }
                .calular-decre {
                    margin-left: 5px;
                    color: white;
                    background: #dc3544;
                    border-radius: 6px;
                    padding: 3px;
                    font-size: 12px;
                }
            }
        }
        .table-keyword-position {
            margin-top: 30px;
            text-align: left;
            border: 1px solid rgb(219, 219, 219);
            border-radius: 4px;

            .action-table-keyword {
                display: flex;
                .badge-keyword {
                    cursor: pointer;
                    padding-right: 10px;
                    font-size: 18px;
                }

                .icon-action-keyword {
                    .remove {
                        font-size: 18px;
                        cursor: pointer;
                    }
                }

                .sort-list-key {
                    display: flex;
                    padding-left: 10px;
                    font-size: 18px;
                    .arrow-up_out {
                        cursor: pointer;
                        &:hover {
                            color: #1a90ff;
                        }
                    }
                    .arrow-down-out {
                        padding-left: 5px;
                        cursor: pointer;
                        &:hover {
                            color: #1a90ff;
                        }
                    }
                }
            }

            .detail-position {
                .calular-incre {
                    margin-left: 5px;
                    color: white;
                    background: #27a844;
                    border-radius: 6px;
                    padding: 3px;
                    font-size: 12px;
                }
                .calular-decre {
                    margin-left: 5px;
                    color: white;
                    background: #dc3544;
                    border-radius: 6px;
                    padding: 3px;
                    font-size: 12px;
                }
            }

            .header-table {
                .button-add {
                    text-align: right;
                    padding: 10px;
                    display: flex;
                    justify-content: flex-end;
                    .reload {
                        line-height: 30px;
                        margin-right: 10px;
                        cursor: pointer;
                        font-size: 16px;
                    }
                }
            }
            .title-keyword {
                font-size: 18px;
                padding-bottom: 10px;
                padding: 15px;
                display: flex;
                .title {
                    width: 50%;
                }
            }
            .count-false {
                text-align: right;
                padding-right: 20px;
                padding-bottom: 20px;
                .list-key-hidden {
                    max-height: 300px;
                    overflow-y: auto;
                }
            }
            .item-keyword {
                padding: 15px;
                border-top: 1px solid rgb(219, 219, 219);

                .calular-incre {
                    margin-left: 5px;
                    color: white;
                    background: #27a844;
                    border-radius: 6px;
                    padding: 3px;
                    font-size: 12px;
                }
                .calular-decre {
                    margin-left: 5px;
                    color: white;
                    background: #dc3544;
                    border-radius: 6px;
                    padding: 3px;
                    font-size: 12px;
                }
            }
        }
        .chart-weekly-categories {
            margin-top: 30px;
            border: 1px solid rgb(204, 204, 204);
            border-radius: 3px;
        }
        .chart-weekly-reviews {
            margin-top: 30px;
            border: 1px solid rgb(204, 204, 204);
            border-radius: 3px;
        }
        .chart-weekly-keyword {
            margin-top: 30px;
            border: 1px solid rgb(204, 204, 204);
            border-radius: 3px;
        }
        .chart-weekly-change {
            margin-top: 30px;
            border: 1px solid rgb(204, 204, 204);
            border-radius: 3px;

            .block-header {
                padding: 15px;
                text-align: left;
                border-bottom: 1px solid rgb(204, 204, 204);
                font-weight: bold;
            }
            .chart {
                padding: 30px 15px 30px 15px;
            }
        }

        .diff-viewer {
            margin-top: 30px;
            .item-viewer {
                margin-top: 20px;
                border: 1px solid rgb(204, 204, 204);
                padding: 5px;
            }
        }

        .chart-weekly-rating {
            margin-top: 30px;
            border: 1px solid rgb(204, 204, 204);
            border-radius: 3px;
            // padding: 15px;
        }

        .data-from-ga {
            margin-top: 30px;
        }

        .detail-app-view {
            .title {
                font-size: 22px;
                text-align: left;
                margin-top: 15px;
                margin-bottom: 10px;
            }
            .name-app {
                display: flex;
                img {
                    width: 70px;
                    height: 70px;
                }
                .info {
                    text-align: left;
                    margin-left: 30px;
                    .name {
                        font-size: 28px;
                        font-weight: bold;
                    }
                    .tag-line {
                        font-size: 20px;
                    }
                    .review {
                        font-size: 16px;
                        .star {
                            color: #d8aa34;
                        }
                    }
                    .price {
                        font-size: 16px;
                    }
                }
            }
            // .feature-app {
            // }
            .description-app {
                .title {
                    font-size: 28px;
                    font-weight: bold;
                }
                text-align: left;
            }
            .media-gallery-app {
                .title {
                    font-size: 28px;
                }
                .list-image {
                    display: flex;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    .item-image {
                        margin-right: 20px;

                        .detail-image {
                            max-height: 200px;
                            max-width: 200px;
                            margin-right: 10px;
                            flex-grow: 4;
                        }
                    }
                }
            }
        }
    }
    .competitor {
        text-align: left;
        padding: 15px 50px;
        .ant-tabs-nav-add {
            padding-left: 2px;
            padding-right: 0px;
        }
        .add-competitor {
            .ant-btn-dashed {
                color: #11b24b;
                border-color: #11b24b !important;
            }

            .ant-btn-dashed:hover,
            .ant-btn-dashed:focus {
                border-color: #93cc39 !important;
                color: #93cc39;
            }
        }
    }

    .header-detail-app-info {
        text-align: left;
        margin-top: 10px;
        padding: 0 50px;
        display: flex;

        .header-detail-app-info-left {
            width: 70%;
            .detail-app {
                display: flex;
                .detail-app-info {
                    display: flex;
                    .image {
                        img {
                            width: 77px;
                            height: 77px;
                        }
                    }
                    .title-app {
                        margin-left: 10px;
                        .name {
                            font-size: 26px;
                            font-weight: bold;
                        }
                    }
                }
            }

            .view-on-shopify {
                margin-top: 10px;
                span {
                    color: #717981;
                }
                a {
                    color: black;
                    &:hover {
                        text-decoration: underline;
                        color: #40a9ff;
                    }
                }
            }
            .rating {
                margin-top: 15px;
                .star {
                    color: #d8aa34;
                }
            }
        }
        .header-detail-app-info-right {
            text-align: right;
            width: 30%;

            .button-waiting-connect {
                background-color: #d8ab34;
                border: none;
            }
        }
    }
}
#chartjs-tooltip {
    border: 1px solid rgb(190, 190, 190);
    padding: 10px;
    background-color: #fdeea2;
    #tooltip-change-log {
        &:hover {
            cursor: pointer;
        }
    }
    #button-change-log {
        color: black;
        &:hover {
            cursor: pointer;
        }
    }
}

.popup-change-log {
    .ant-modal-body {
        max-height: 500px;
        overflow: auto;
    }
}
.content-popup-change-log {
    max-height: 500px !important;
    overflow: auto !important;
}
#chartjs-tooltip {
    cursor: pointer;
}