.about-us {
  width: 100%;
  margin-top: 30px;
  text-align: left;

  .page-header {
    font-size: 24px;
  }

  p {
    text-align: justify;
  }
}
