.privacy-policy {
  display: flex;
  width: 100%;
  margin-top: 30px;
  text-align: left;
  p {
    text-align: justify;
    padding-left: 20px;
  }
  h3 {
    padding-left: 20px;
  }
}
