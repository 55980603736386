.search-shopify {
    margin-left: 17%;
    margin-right: 17%;
    .list-search-detail {
        margin-top: 30px;
        .info-search-detail {
            .title-name {
                font-size: 40px;
                font-weight: bold;
            }
        }
        .body-search-detail {
            margin-top: 25px;
            .title-app {
                margin-top: 10px;
                font-size: 18px;
                text-align: center;
            }
        }
        .pagination {
            margin-top: 30px;
        }
        .result-developer {
            .title {
                font-size: 18px;
            }
            margin-top: 20px;
            
            text-align: center;
            .content-item {
                
                .gutter-column {
                    padding: 10px;
                    background: #F8F9FA;
                }
            }
        }
    }
}
