.layout-homepage {
    background-color: #FFFFFF;
    .total-statistic {
        display: flex;
        justify-content: center;
        background-color: #0b1d46;
        color: white;
        font-weight: bold;
        padding: 15px;
        font-size: 16px;
        // .total-apps {

        // }
        .total-developers {
            margin-left: 10%;
            margin-right: 10%;
        }
        // .total-reviews {

        // }
    }
    .content-homepage {
        margin-left: 18%;
        margin-right: 18%;
        .new-app {
            margin-top: 50px;
        }
        .top-movers {
            margin-top: 100px;
        }
        .most-reivewed {
            margin-top: 100px;
        }
    }
}
