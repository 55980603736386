.developer {
    margin-top: 40px;
    margin-left: 17%;
    margin-right: 17%;
    .header-developer {
        font-size: 32px;
        font-weight: bold;
    }
    .list-developer {
        .pagination {
            margin-top: 30px;
        }
    }
}