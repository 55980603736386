.item-developers {
    text-align: left;
    margin-top: 10px;
    .app-name {
        background: #F8F9FA;
        border-bottom: 1px solid rgb(214, 214, 214);
        font-size: 22px;
        padding: 20px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        

    }
    .amount-app {
        font-size: 16px;
        background: #F0F1F2;
        padding: 15px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }
}