.item-app-homepage {
    box-shadow: 2px 4px 5px 0px #dad9d9;
    .info-item {
        padding: 20px;
        background-color: #F8F9FA;
        border-radius: 3px;
        
        .item {
            display: flex;
            .image-item {
                img {
                    width: 75px;
                    height: 75px;
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
            .text-item {
                margin-left: 10px;
                text-align: left;
            }
        }
    }
    .text-description {
        border-top: 1px solid;
        background-color: #F0F1F2;
        padding-top: 20px;
        padding-bottom: 20px;
        text-align: left;
        padding-left: 20px;
    }
}