@import "../utils/variables.scss";
#components-layout-demo-fixed-sider .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}
.sasi-layout {
  .ant-layout-content {
    min-height: 78vh;
  }
  .sasi-layout-background {
    background-color: #000e2d;
    height: fit-content;

    .header-sasi {
      .header-profile {
        text-align: right;
        align-items: center;
        padding-left: 15px;
        .avatar-profile-header {
          display: inline-block;
        }
        .profile-name {
          padding-left: 6px;
          font-weight: bold;
          font-size: 16px;
          display: inline-block;
        }
        &:hover {
          cursor: pointer;
        }
      }
      .menu-sasi {
        display: flex;
        justify-content: center;
        width: 100%;
        text-align: left;
        line-height: 75px;
        .logo-sasi {
          .ant-menu-inline,
          .ant-menu-vertical,
          .ant-menu-vertical-left {
            border-right: none;
          }

          .ant-menu {
            text-align: center;
            background: none;
            border: none;
            .ant-menu-item {
              height: fit-content;
              background-color: transparent;
              padding-left: 0;
            }
          }
          cursor: pointer;
          width: 30%;
          .img-responsive {
            border-radius: 7px;
            height: 70px;
            margin-top: 5px;
          }
          img {
            vertical-align: middle;
            border-style: none;
          }
        }
        .menu-right {
          width: 50%;
          .list-menu {
            // display: flex;
            // justify-content: flex-end;
            .ant-menu-horizontal {
              border-bottom: none;
              color: rgb(219, 219, 219);
              text-align: right;

              .ant-menu-overflow-item {
                &::after {
                  border: none;
                }
                &:hover {
                  color: white;
                  border-bottom: none;
                }
                .ant-menu-horizontal > .ant-menu-item a:hover {
                  color: none;
                }
                .ant-menu-item a:hover {
                  color: none;
                }
              }
              .ant-menu-item-selected {
                color: white;
              }
            }
          }
          .ant-menu {
            background: none;
            border-right: none;
          }
        }
        .search-data {
          width: 20%;
          .ant-input-search {
            vertical-align: middle;
          }
        }
        .button-login {
          line-height: 77px;
          padding-left: 2px;
        }
      }
    }
  }
  .ant-layout-content {
    background: white;
    .content-menu {
      background-color: #ffffff;
      margin-bottom: 50px;
    }
  }
  .create-account {
    display: flex;
    background-color: #818a90;
    justify-content: flex-end;
    width: 100%;
    padding: 13px 50px;
    .link-sign {
      a {
        color: white;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .link-create-account {
      padding-left: 20px;
      a {
        color: white;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.App-logo {
  max-height: 45px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.profile-dropdown {
  // left: 1125px;
  // top: 155px;
  background: #e2e2e2 !important;
}

@media (max-width: 1200px) {
  .logo-sasi {
    display: none;
  }

  .menu-right {
    width: 20% !important;
  }

  .search-data {
    width: 100% !important;
  }
}
