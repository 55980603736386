.change-passwrod {
    padding-top: 210px;
    background-color: #F9F9FB;
    min-height: 100vh;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding-left: 30%;
    padding-right: 30%;
    .authen-box {
        text-align: left;
        .ant-row {
            justify-content: center;
        }
        .form-login {
            .ant-form-item-label {
                text-align: left;
            }
            .ant-form-item-required {
                color: #777777;
            }
            .form-input {
                height: 36px;
                border-radius: 4px;
            }
        }
    }
}