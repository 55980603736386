.item-app {
    box-shadow: 2px 4px 5px 0px #dad9d9;
    height: 100%;
    .info-item {
        padding: 15px;
        background-color: #F8F9FA;
        border-radius: 3px;
        height: 100%;

        .item-develop {
            display: flex;
            .image-item {
                img {
                    width: 80px;
                    height: 80px;
                }
            }
            text-align: left;
            .text-item {
                text-align: left;
                margin-left: 10px;
            }
            .tagline {
                margin-left: 10px;
                text-align: left;
            }
        }
    }
    .text-description {
        border-top: 1px solid;
        background-color: #F0F1F2;
        padding-top: 20px;
        padding-bottom: 20px;
        text-align: left;
        padding-left: 20px;
    }
}